<template>
  <div>
    <rectangle-element
      :layout="layout.background"
      :skeleton="skeleton"
    ></rectangle-element>
    <rectangle-element
      :layout="layout.imageBackground"
      :skeleton="skeleton"
    ></rectangle-element>
    <image-element
      :url="content.downloadUrl"
      :layout="layout.product"
      :skeleton="skeleton"
      :hasImage="advancedLayout"
    ></image-element>
    <text-element
      :text="content.name"
      :layout="layout.title"
      :skeleton="skeleton"
    ></text-element>
    <text-element
      :text="content.description"
      :layout="layout.text"
      :skeleton="skeleton"
      :hasImage="advancedLayout"
    ></text-element>
    <text-element
      class="md-whiteframe-5dp"
      v-if="advancedLayout && content.newPrice"
      :text="content.newPrice"
      :layout="layout.newprice"
      :skeleton="skeleton"
    ></text-element>
    <text-element
      class="line-through"
      v-if="advancedLayout"
      :text="content.currentPrice"
      :layout="layout.currentPrice"
      :skeleton="skeleton"
    ></text-element>
    <text-element
      class="md-whiteframe-5dp"
      v-if="advancedLayout && content.discount"
      :text="content.discount"
      :layout="layout.discount"
      :skeleton="skeleton"
    ></text-element>
    <text-element
      v-if="hasCategory"
      :text="content.category"
      :layout="layout.category"
      :skeleton="skeleton"
      class="md-whiteframe-5dp"
    ></text-element>
    <text-element
      v-if="hasDates"
      :text="promotionDates"
      :layout="layout.dates"
      :skeleton="skeleton"
      class="md-whiteframe-5dp"
      :hasImage="advancedLayout"
    ></text-element>
  </div>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";
import moment from "moment";

export default {
  name: "PromotionElement",
  mixins: [layoutMixin],
  components: {
    TextElement: () => import("@/components/core/TextElement"),
    RectangleElement: () => import("@/components/core/RectangleElement"),
    ImageElement: () => import("@/components/core/ImageElement"),
  },
  props: {
    content: Object,
  },
  data() {
    return {
      num: 0,
    };
  },
  mounted() {},
  destroyed() {},
  computed: {
    advancedLayout: function() {
      if (this.content && this.content.promoType == 1) {
        return true;
      }

      return false;
    },
    hasDates: function() {
      if (this.content.startDate || this.content.endDate) {
        return true;
      }
      return false;
    },
    hasCategory: function(){
      if(this.content.category){
        return true;
      }
      return false;
    },
    promotionDates: function() {
      var ret = "";
      var startDate = moment(this.content.startDate);
      var endDate = moment(this.content.endDate);
      var hasStartDate = false;
      var hasEndDate = false;
      if (startDate.isValid()) {
        hasStartDate = true;
      }
      if (endDate.isValid()) {
        hasEndDate = true;
      }

      if (hasStartDate) {
        if (hasEndDate) {
          ret += "dal " + startDate.format("DD-MM");
        } else {
          ret += "dal " + startDate.format("DD MMMM");
        }
      }

      if (hasEndDate) {
        if (hasStartDate) {
          ret += " al " + endDate.format("DD-MM");
        } else {
          ret += "fino al " + endDate.format("DD MMMM");
        }
      }

      return ret.toUpperCase();
    },
  },
  methods: {},
};
</script>
<style scoped>
.md-whiteframe-5dp {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14),
    0 1px 14px 0 rgba(0, 0, 0, 0.12);
}
</style>
